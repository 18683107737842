<template>
  <component :is="courseData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="courseData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching event data
      </h4>
      <div class="alert-body">
        No course found with this event id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'dashboard-courses-list'}"
        >
          Courses List
        </b-link>
        for other courses.
      </div>
    </b-alert>
    <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
    >
      <b-form @submit.prevent="onUpdate">
      <b-row>
        <b-col md="6">
          <validation-provider
              #default="validationContext"
              name="English Title"
              rules="required"
          >
            <b-form-group
                label="Title En"
                label-for="mc-title-en"
            >
              <b-form-input
                  v-model="courseData.title_en"
                  id="mc-title-en"
                  placeholder="Title En"
                  :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Title Ar"
              label-for="mc-title-ar"
          >
            <b-form-input
                v-model="courseData.title_ar"
                id="mc-title-ar"
                placeholder="Title Ar"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <validation-provider
              #default="validationContext"
              name="English Description"
              rules="required"
          >
            <b-form-group
                label="English Description"
                label-for="description-en"
            >
              <b-form-textarea
                  id="description-en"
                  v-model="courseData.description_en"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Arabic Description"
              label-for="description-ar"
          >
            <b-form-textarea
                id="description-ar"
                v-model="courseData.description_ar"
                autofocus
                trim
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Price"
              label-for="mc-price"
          >
            <validation-provider
                #default="{ errors }"
                name="Price"
                rules="required|positive|between:1,99999"
            >
              <cleave
                  id="number"
                  v-model="courseData.price"
                  class="form-control"
                  :raw="false"
                  :options="mask.number"
                  placeholder="10,000"
                  :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Course Has Discount"
              label-for="landmark"
          >
            <b-form-radio
                v-model="have_discount"
                class="custom-control-primary"
                :name="'have_discount'"
                value="1"
            >
              Yes
            </b-form-radio>
            <b-form-radio
                v-model="have_discount"
                class="custom-control-primary"
                :name="'have_discount'"
                value="0"
            >
              No
            </b-form-radio>
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="have_discount == 1">
          <b-form-group
              label="Price After Discount"
              label-for="mc-price-after"
          >
            <validation-provider
                #default="{ errors }"
                name="Price After Discount"
                rules="required|positive|between:1,99999"
            >
              <cleave
                  id="number"
                  v-model="courseData.price_after_discount"
                  class="form-control"
                  :raw="false"
                  :options="mask.number"
                  placeholder="Price after discount"
                  :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Expire Date Type"
              label-for="mc-expire-date-type"
          >
            <v-select
                v-model="expire_date_type"
                @input="changeExpireType"
                :reduce="val => val.value"
                :options="expire_date_type_options"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="expire_date_type == 'date'">
          <b-form-group
              label="Expire Date"
              label-for="mc-expire-date"
          >
            <validation-provider
                #default="{ errors }"
                name="Expire Date"
                rules="required"
            >
              <b-form-datepicker
                  v-model="courseData.expire_date"
                  :date-disabled-fn="dateDisabled"
                  id="mc-expire-date"
                  placeholder="Expire Date"
                  :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="expire_date_type == 'duration'">
          <b-form-group
              label="Expire Date"
              label-for="mc-expire-date"
          >
            <validation-provider
                #default="{ errors }"
                name="Expire Date"
                rules="required"
            >
              <v-select
                  v-model="courseData.expire_duration"
                  :options="expire_date_duration_options"
                  :reduce="val => val.value"
                  :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Select Speciality"
              label-for="mc-speciality"
          >
            <validation-provider
                #default="{ errors }"
                name="Speciality"
                rules="required"
            >
              <v-select
                  v-model="courseData.speciality_id"
                  :options="specialities_options"
                  :reduce="val => val.value"
                  :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
              label="Is Active"
              label-for="mc-is-active"
          >
            <b-form-checkbox
                id="mc-is-active"
                v-model="courseData.is_active"
                checked="true"
                class="custom-control-success"
                name="check-button"
                switch
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <file
              title="Select Cover Image"
              :key="rerender_component"
              :default_place_holder_src="courseData.image"
              @file_uploaded="({media})=>{courseImageId= media.id;courseData.image = media.url}"
          ></file>
        </b-col>
      </b-row>
      <b-row style="margin-top: 20px">
        <b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Submit
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>
  </component>
</template>

<script>

import {ref, onUnmounted} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import courseStoreModule from '../courseStoreModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BAlert,
  BFormRadio,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormGroup,
  BFormFile,
  BFormInput,
  BLink,
  BRow,
  BFormDatepicker,
  BFormTextarea
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";
import { required, alphaNum, email,url } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    BFormRadio,
    BFormDatepicker,
    BCard,
    Cleave,
    BFormInvalidFeedback,
    BFormFile,
    BFormTextarea,
    BAlert,
    BLink,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    vSelect,

    //Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  created() {
    this.$http.get('/all-courses')
        .then(response => {
          let that = this
          this.lists.splice(0)
          this.lists = response.data.data.map(function(item){
            that.courseOptions.push({
              label : item.title_en,
              value: item.id+""
            })
          })
          let course_id = this.courseData.course_id
          this.updateCourseID(course_id)
        })
    this.$http.get('all-specialities')
        .then(res => {
          if (res.data.status_code == 1){
            let that = this
            this.lists = res.data.data.map(function(item){
              that.specialities_options.push({
                label : item.title_en,
                value: item.id
              })
            })
          }
        })
  },
  methods:{
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      const month = date.getMonth();
      const year = date.getFullYear();

      const current = new Date();
      const currentDay = current.getDate()
      const currentMonth = current.getMonth()
      const currentYear = current.getFullYear()

      // Return `true` if the date should be disabled
      if (year < currentYear){
        return true
      }
      if (year <= currentYear){
        if (month <= currentMonth){
          if (day <= currentDay && year <= currentYear){
            return true
          }else if (day >= currentDay && month < currentMonth){
            return true
          }
        }
      }
    },
    updateCategoryId(category_id){
      this.courseData.category_id = category_id
    },
    changeExpireType(){
      this.courseData.expire_date = ''
      this.courseData.expire_duration = ''
    },
    updateCourseID(course_id){
      this.courseData.course_id = course_id
      this.$http.get('/categories?course_id='+course_id)
          .then(response => {
            let that = this
            this.categoriesOptions.splice(0)
            this.lists.splice(0)
            this.lists = response.data.data.map(function(item){
              that.categoriesOptions.push({
                label : item.label,
                value: item.value+""
              })
            })
          })
    },
    setImage(imageId) {
      this.courseData.video_thumb = imageId
    },
    changeValue(){
      let val = this.courseData.video_type
      this.courseData.video_type = val
    },
    uploadVideoToVimeo(event){
      this.show = true
      let data = new FormData();
      data.append('name', 'my-pdf');
      data.append('file', event.target.files[0]);

      let config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      this.$http.post('lectures/upload-video',
          data,
          config
      ).then(response => {
        this.courseData.path = response.data.url
        this.show = false
      })
    },
  },
  data(){
    return{
      specialities_options: [],
      expire_date_type_options: [
        {
          label : "Fixed Duration",
          value: "duration"
        },
        {
          label : "Date",
          value: "date"
        }
      ],
      expire_date_duration_options: [
        {
          label : "1 Month",
          value: "1 Month"
        },
        {
          label : "3 Months",
          value: "3 Months"
        },
        {
          label : "6 Months",
          value: "6 Months"
        }
      ],
      required,
      url,
      courseOptions: [],
      rerender_component: 0,
      show: false,
      lists: [],
      mask: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'wan',
          delimiter: '',
          numericOnly: true,
        },
      },
      categoriesOptions: [],
      video_type: null,
      video_type_options: [
        {
          label : "Upload video",
          value: "upload"
        },
        {
          label : "Insert a URL",
          value: "url"
        }
      ],
    }
  },
  setup() {
    const blankCourseData = {
      title_en: null,
      title_ar: null,
      is_active: true,
      speciality_id: null,
      description_en: null,
      description_ar: null,
      price: null,
      expire_date: null,
      expire_duration: null,
      expire_date_type: null,
      id: null,
      image: null,
      price_after_discount: null
    }
    const courseImageId = ref(null);
    const have_discount = ref(null);
    const expire_date_type = ref(null);
    const toast = useToast()

    const courseData = ref(JSON.parse(JSON.stringify(blankCourseData)))
    const resetCourseData = () => {
      courseData.value = JSON.parse(JSON.stringify(blankCourseData))
    }

    const EVENT_APP_STORE_MODULE_NAME = 'courses'

    // Register module
    if (!store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.registerModule(EVENT_APP_STORE_MODULE_NAME, courseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.unregisterModule(EVENT_APP_STORE_MODULE_NAME)
    })
    store.dispatch('courses/fetchCourse', {id: router.currentRoute.params.id})
        .then(response => {
          if (response.data.data.price_after_discount > 0){
            have_discount.value = 1
          }
          courseData.value = response.data.data
          if (response.data.data.expire_duration != null){
            expire_date_type.value = 'duration'
          }else{
            expire_date_type.value = 'date'
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            courseData.value = undefined
          }
        })

    const onUpdate = () => {
      let data = {
        title_en: courseData.value.title_en,
        title_ar: courseData.value.title_ar,
        description_en: courseData.value.description_en,
        description_ar: courseData.value.description_ar,
        is_active: courseData.value.is_active,
        price: courseData.value.price,
        price_after_discount: courseData.value.price_after_discount,
        expire_date: courseData.value.expire_date,
        expire_duration: courseData.value.expire_duration,
        speciality_id: courseData.value.speciality_id,
      }
      if (courseImageId.value != null) {
        data.image = courseImageId.value
      }
      if (expire_date_type.value == 'duration'){
        data.expire_date = null
      }else{
        data.expire_duration = null
      }
      if (have_discount.value == 0){
        data.price_after_discount = null
      }
      store.dispatch('courses/updateCourse', {
        id: router.currentRoute.params.id,
        data: data
      })
          .then(response => {
            router.push({name : 'dashboard-courses-list'})
            toast({
              component: ToastificationContent,
              props: {
                title: 'Course Updated Successfully',
                icon: 'EditIcon',
                position: 'top-center',
                variant: 'success',
              },
            }, {
              position: 'top-center'
            })
          })
          .catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'EditIcon',
                position: 'top-center',
                variant: 'error',
              },
            }, {
              position: 'top-center'
            })
          })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCourseData)
    return {
      onUpdate,
      courseData,
      courseImageId,
      have_discount,
      expire_date_type,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
